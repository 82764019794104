import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';
import { WorkActivity } from '../class/work-activity.class';
import { Item, ItemMatrix, ItemMatrixTypeEnum, ItemStock, ItemType } from '../class/item.class';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpClient) { 
    
  }

  getSelect() {
    
    return this.http.get<Array<Item>>(`${environment.api_site}/item/select`);    

  }

  // getAll() {
    
  //   return this.http.get<Array<Item>>(`${environment.api_site}/item`);    

  // }

  getAllByQuery(queries : object) : Observable<Array<Item>> {

    let params = new HttpParams();

    for (var key in queries) {
      //console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);

    return this.http.get<Array<Item>>(`${environment.api_site}/item`, { params });  
  }

  // all tutte , in = infisse out = estratte
  getSitePalancole(site_id : string, status : string = "all") {

    let params = new HttpParams();

    //type === ItemType.palancole

    params = params.append("type", ItemType.palancole);
    params = params.append("site_id", site_id);

    params = params.append("status", status);
    
    return this.http.get<Array<ItemStock>>(`${environment.api_site}/item/stock`, { params });    

  }

  getPalancoleTypeOnSite(site_id : string, status : string = "all") {

    let params = new HttpParams();

    //type === ItemType.palancole

    params = params.append("type", ItemType.palancole);
    params = params.append("site_id", site_id);

    params = params.append("status", status);
    
    return this.http.get<Array<ItemStock>>(`${environment.api_site}/item/available-type`, { params });    

  }

  
  

  getById(id: string) {
    
    return this.http.get<Item>(`${environment.api_site}/item/${id}`);    

  }

  insert(data: Item) {
    
    return this.http.post<Item>(`${environment.api_site}/item/`, data);    

  }

  

  update(data: Item) {
    
    return this.http.put<Item>(`${environment.api_site}/item/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/Item/${id}`);    

  }



}
