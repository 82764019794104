import { HttpClient , HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { lastDayOfDecade } from 'date-fns';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Log } from '../class/log.class';
import { System } from '../class/system.class';

@Injectable({
  providedIn: 'root'
})
export class EffectLoadingService {

  public isloading : boolean = false;

  public interval: any;

  constructor(private loadingCtrl: LoadingController) {}
    

  async Show(timeSeconds:number = 0) {

    //console.log("show");

    this.isloading = true;

    this.interval = setInterval(()=> this.Hide(),3 * 1000);

  }

  Hide() {

    //console.log("hide");
    

    clearInterval(this.interval);

    this.isloading = false;
  }
}
