import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';
import { ItemMovment } from '../class/item-movment.class';
import { MovmentType } from '../class/movment-type.class';

@Injectable({
  providedIn: 'root'
})
export class MovmentTypeService {

  constructor(private http: HttpClient) { 
    
  }

  getSelect() {
    
    return this.http.get<Array<any>>(`${environment.api_site}/movment-type/select`);    

  }

  getAll() {
    
    return this.http.get<Array<MovmentType>>(`${environment.api_site}/movment-type`);    

  }

  

  getById(id: string) {
    
    return this.http.get<MovmentType>(`${environment.api_site}/movment-type/${id}`);    

  }

  insert(data: MovmentType) {
    
    return this.http.post<MovmentType>(`${environment.api_site}/movment-type/`, data);    

  }

 
  update(data: MovmentType) {
    
    return this.http.put<MovmentType>(`${environment.api_site}/movment-type/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/movment-type/${id}`);    

  }

}
