import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private readonly COUNTRY_JSON = './assets/data/countries.json'
  private readonly REFRESH_TOKEN = "REFRESH_TOKEN";

  constructor(private http: HttpClient) { }

  public GetCountries(): Promise<any> {

    return this.http.get(this.COUNTRY_JSON).toPromise();

  }


  public convertBase64(file): Promise<any> {

    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = async () => {        

        let resized_image = await this.resizeImage(fileReader.result.toString());

        resolve(resized_image);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  private resizeImage(base64Str : string) : Promise<any> {

    var img = new Image();
    img.src = base64Str;

    return new Promise((resolve, reject) => {

      img.onload = () => {

        var canvas = document.createElement('canvas');
        var MAX_WIDTH = 400;
        var MAX_HEIGHT = 350;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        resolve(canvas.toDataURL());

      }

      img.onerror = (err) => {
        reject(err);
      }

    });

    
  }




}
