import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Item } from '../../class/item.class';
import { Site } from '../../class/site.class';
import { SiteService } from '../../services/site.service';
import { ItemService } from '../../services/item.service';
import { MovmentTypeService } from '../../services/movment-type.service';
import { ItemMovmentService } from '../../services/item-movment.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'item-movment',
  templateUrl: './item-movment.component.html',
  styleUrls: ['./item-movment.component.scss'],
})
export class ItemMovmentComponent implements OnInit {


  // public site_select: Array<Site> = []

  // public item_select: Array<Item> = []

  // public movment_type_select: Array<Item> = []

  @Input()
  public formGroupItemMovment: FormGroup;

  //indica se infissione o estrazione
  @Input()
  public movment_type: string

  public availableQty: number = 0;

  // @Output()
  // public onRemove = new EventEmitter<FormGroup>();

  @Output()
  public onQtyChange = new EventEmitter<FormGroup>();

  skipQtyChangeEvent : boolean = false


  @Input()
  public edit: boolean = true;

  // siteIsSelected : boolean = false;
  // itemIsSelected : boolean = false;
  // movTypeIsSelected : boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private movmentTypeService: MovmentTypeService,
    private itemMovmentService : ItemMovmentService,
    private itemService: ItemService,
    private siteService: SiteService) {

      

  }

  async ngOnInit() {

    // this.formGroupItemMovment.controls['available'].value

    this.setMaxAvailable(parseFloat(this.formGroupItemMovment.controls['available'].value))

    this.formGroupItemMovment.controls['available'].valueChanges.subscribe(data =>{
      
      this.setMaxAvailable(parseFloat(data))      

    });

    this.formGroupItemMovment.controls['qty'].valueChanges.subscribe(data =>{
      
      // console.log("qty changes",data);
      
      if(!this.skipQtyChangeEvent){
        this.onQtyChange.emit(this.formGroupItemMovment)   
      }

    });

  }


  get f() { return this.formGroupItemMovment.controls; }


  setMaxAvailable(qtyValue : number){


    // console.log('available valueChanges', qtyValue);

    this.skipQtyChangeEvent = true;

    if(qtyValue < 0)  qtyValue *= -1
    

    this.formGroupItemMovment.controls["qty"].clearValidators()
    

    // if(qtyValue > 0){     

      this.formGroupItemMovment.controls["qty"].addValidators([
        Validators.required,
        Validators.min(0), 
        Validators.max(qtyValue)])
    // } else {

    //   this.formGroupItemMovment.controls["qty"].addValidators([
    //     Validators.required,
    //     Validators.min(0)])
    // }

    // console.log(this.formGroupItemMovment.controls["qty"]);
    
    
    this.formGroupItemMovment.controls["qty"].updateValueAndValidity()


    this.availableQty = qtyValue
      

    this.formGroupItemMovment.markAllAsTouched();

    this.skipQtyChangeEvent = false;

  }


  // public onSiteSelect($event: any) {

  //   let inputValue = $event.detail.value;
  //   let inputName = $event.target.control;

  //   this.formGroup.controls['site_id'].setValue(inputValue);



  // }

  // public onItemSelect($event: any) {

  //   let inputValue = $event.detail.value;
  //   let inputName = $event.target.control;

  //   this.formGroup.controls['item_id'].setValue(inputValue);



  // }

  // public onMovmentTypeChange($event: any) {

  //   let inputValue = $event.detail.value;
  //   let inputName = $event.target.control;

  //   this.formGroup.controls['movment_type_code'].setValue(inputValue);

  //   this.updateMaxQtyValue()

    

  // }

  // remove() {
  //   this.onRemove.emit()
  // }

  // public async updateMaxQtyValue(){

  //   console.warn("DISABILITATO");
    

  //   // let itemId = this.formGroup.controls['item_id'].value
  //   // let siteId = this.formGroup.controls['site_id'].value

  //   // // console.log(itemId, siteId);   


  //   // console.log(this.itemIsSelected,this.siteIsSelected);

  //   // if(this.itemIsSelected && this.siteIsSelected){
      
  //   //   let maxQty = await this.itemMovmentService.getSotckInSite(itemId,siteId).toPromise()

  //   //   // console.log("maxQty", maxQty);

  //   //   this.formGroup.controls['qty'].clearValidators();

  //   //   // console.log("add validator");
      
      
  //   //   this.formGroup.controls['qty'].setValidators([Validators.required,Validators.max(maxQty)])

      
  //   // }

  //   // this.formGroup.controls['qty'].updateValueAndValidity()


    

  // }


}
