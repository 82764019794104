import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { get } from 'lodash';
import { Pagination } from '../../interfaces/pagination.interfaces';

export class ColDef{
  type : "COUNT" | "TEXT" | "IMG" | "DATE";
  name : string;
  header : string;
}

export class ButtonDef { 
  text : string | null;
  icon : any | null;
  color : "primary" | "success" | "warning" | "danger" | "medium";
  callback : Function | null;
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {

  // public companies$ : Observable<Array<Company>>;

  public _data : any | null;
  @Input()
  public data: any | null;

  @Input()
  public cols: Array<ColDef>;

  // mi server per il foreach
  public _pageCount: Array<any> = [];
  MAX_PAGE_BEFORE_AFTER : number = 2;
  public pagination: Pagination;


  @Input()
  public buttons: Array<ButtonDef>;

  public headers : Array<any>;

  @Input()
  public showDeleteButton: boolean = false;

  @Input()
  public showEditButton: boolean = true;

  @Input()
  public showNavigationButton: boolean = false;

  @Input()
  public navigationButtonUrlIcon: any | null = 'search';

  @Output()
  public navigationButtonUrlEvent = new EventEmitter<any>();


  @Output()
  public selectedEvent = new EventEmitter<any>();

  @Output()
  public editEvent = new EventEmitter<any>();

  @Output()
  public deleteEvent = new EventEmitter<any>();



  @Output()
  public nextPageEvent = new EventEmitter<number>();
  @Output()
  public prevPageEvent = new EventEmitter<number>();
  @Output()
  public gotoToPageEvent = new EventEmitter<number>();
  


  public searchFilter : any;
  public filterargs : Array<any> = [];


  public get = get;

  constructor() { }

  ngOnInit() {

    // this.headers = this.cols.map(v =>{ return { header : v.header , name : v.name , sorted : false, type : v.type }});

    // this.filterargs = this.cols.filter(v => v.type === "TEXT").map(v => v.name);

    // if(this.data.data){
    //   // nel caso di paginazione
    //   this._data = this.data.data
    // } else {
    //   this._data = this.data;
    // }

    // console.log(this.data);
    

  }

  ngOnChanges(value){
    // console.log(value);

    this.headers = this.cols.map(v =>{ return { header : v.header , name : v.name , sorted : false, type : v.type }});

    this.filterargs = this.cols.filter(v => v.type === "TEXT").map(v => v.name);

    if(this.data?.data){
      // nel caso di paginazione
      this._data = this.data.data
      
      this.pagination = <Pagination>this.data.meta;

      this._pageCount = this.generatePageList(this.pagination.pageCount);

    } else {
      this._data = this.data;
    }

    // console.log(this.data);
    // console.log(this.data?.meta);
    // console.log(this.pagination);
    
    
  }

  navigateButtonClick(item: any){

    // creazione lista parametri per querystring

    this.navigationButtonUrlEvent.emit(item)

  }


  viewItem(item: any){
    //console.log("viewItem", item);    
    this.selectedEvent.emit(item);
  }

  editItem(item: any){
    //console.log("editItem", item);
    this.editEvent.emit(item);
  }

  deleteItem(item: any, index : number){
    //console.log("deleteItem", item);
    this.deleteEvent.emit({item,index});
  }


  public sort(index: number,property:string, sorted : boolean){

    // console.log("sort", property, sorted, this.data);

    this.data = this.data.sort((a,b) => {
      if(sorted) {
        return a[property] - b[property] ? 1 : -1;
      } else {
        return b[property] - a[property] ? 1 : -1;
      }     
      
    })

    this.headers[index].sorted = !sorted;

  }


  // pagnation
  generatePageList(pageCount : number) : Array<any> {

    // console.log("this.pagination.page",this.pagination.page);

    // console.log("pageCount",pageCount);

    let min = this.pagination.page - this.MAX_PAGE_BEFORE_AFTER - 1;

    // console.log("min",min);

    let max = this.pagination.page + this.MAX_PAGE_BEFORE_AFTER + 1;

    // console.log("max",max);
    

    let delta = (this.MAX_PAGE_BEFORE_AFTER * 2) + 1;

    // console.log("delta",delta);

    let result = [];

    for (let index = min; index < max; index++) {
        // console.log("index", index);        
        
        if(index > 0 && index < this.pagination.pageCount){
         
          result.push(index);
        }
      
    }


    return result;
  }

  prevPage(){
    if(this.pagination?.hasPreviousPage){
      this.prevPageEvent.emit(this.pagination.page - 1);
    }    
  }

  nextPage(){
    if(this.pagination?.hasNextPage){
      this.nextPageEvent.emit(this.pagination.page + 1);
    }
  }

  goToPage(pageNumber: number){
    this.gotoToPageEvent.emit(pageNumber);
  }

}
