import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../services/token.service';
import { User, UserRoleTypeEnum } from '../class/user.class';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService,
        private tokenService: TokenService,
    ) { }


    canActivate(
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) {        

        const user : User = this.authService.currentUserValue;

        let requestedUrl = state.url.toLocaleLowerCase().replace("/","")

        let tokenIsValid = this.tokenService.isTokenValid();

        // console.log("AuthGuard", user ,state);        

        if (user && tokenIsValid) {          

            // console.log("tokenIsValid", tokenIsValid);
            
            // se la pagine richiesta è home va bene
            if(state.url.endsWith("home")){
                return true
            }

            
            // verifica ruolo
            if(user.role !== UserRoleTypeEnum.ADMIN){

                // lista dei permessi utente
                let userPagePermission = this.authService.currentUserRolePermissionValue

                // console.log("userPagePermission", userPagePermission);

                // console.log("requestedUrl",requestedUrl);
                
                if(userPagePermission.map(p => p.code).includes(requestedUrl.split('/')[0])) {
                    return true
                } else {
                    // non è concesso
                    return false
                }

                // l'url è concesso
                // if(userPagePermission.map(p => p.code).includes(requestedUrl)) {
                //     return true
                // } else {
                //     // non è concesso
                //     return false
                // }

                        

            } else {
                // è admin
                return true;
            }


            
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

    }
}