import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'movment-row',
  templateUrl: './movment-row.component.html',
  styleUrls: ['./movment-row.component.scss'],
})
export class MovmentRowComponent implements OnInit {

  @Input()
  public formGroup: FormGroup;

  @Input()
  public edit: boolean = true;

  @Input()
  public item_select?: Array<any>;

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  constructor(
    private formBuilder: FormBuilder) {

      

  }

  ngOnInit() {}

  get f() { return this.formGroup.controls; }


  onItemSelect($event : any){
    
    let selectedId = $event.detail.value;
    
    this.formGroup.controls['item_id'].setValue(selectedId);

  }

  remove() {
    this.onRemove.emit()
  }

}
