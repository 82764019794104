import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../class/user.class';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { 
    
  }
  
  getSelect() {
    
    return this.http.get<Array<User>>(`${environment.api_site}/user/select`);    

  }

  getAll() {
    
    return this.http.get<Array<User>>(`${environment.api_site}/user`);    

  }

  getAllByQuery(queries : object) : Observable<Array<User>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);

    return this.http.get<Array<User>>(`${environment.api_site}/user`, { params });  
  }

  getById(id: string) {
    
    return this.http.get<User>(`${environment.api_site}/user/${id}`);    

  }

  insert(data: User) {
    
    return this.http.post<User>(`${environment.api_site}/user/`, data);    

  }

  update(data: User) {
    
    return this.http.put<User>(`${environment.api_site}/user/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/user/${id}`);    

  }
}
