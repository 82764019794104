import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { SiteService } from '../../services/site.service';
import { Site } from '../../class/site.class';

@Component({
  selector: 'modal-site',
  templateUrl: './modal-site.component.html',
  styleUrls: ['./modal-site.component.scss'],
})
export class ModalSiteComponent implements OnInit {


  public sites_select: Array<Site> = []
  public filtered_sites_select: Array<Site> = []

  constructor(
    private siteService: SiteService,
    private modalCtrl: ModalController) {

  }

  async ngOnInit() {

    await this.siteService.getSelect().toPromise().then(data => {
      this.sites_select = data;
      this.filtered_sites_select = this.sites_select
    });

  }

  cancel() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }

  confirm(siteId : string) {
    return this.modalCtrl.dismiss(siteId, 'confirm');
  }

  filter(event){
    this.filtered_sites_select = this.sites_select
    const query = event.target.value.toLowerCase();
    this.filtered_sites_select = this.sites_select.filter((d) => d.name.toLowerCase().indexOf(query) > -1);
  }
  
}
