import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { System } from '../../class/system.class';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'system-select',
  templateUrl: './system-select.component.html',
  styleUrls: ['./system-select.component.scss'],
})
export class SystemSelectComponent implements OnInit {


  public system_select : Array<System> = []

  @Input() 
  public formGroupSystemSelect: FormGroup;

  @Output() 
  public onRemove = new EventEmitter<FormGroup>();

  @Input() 
  public edit : boolean = true;

  get f() { return this.formGroupSystemSelect.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private systemService : SystemService) {
      
    }

  async ngOnInit() {

    await this.systemService.getSelect().toPromise().then(data => {
      this.system_select = data;
    })


  }

  onSystemSelect($event : any){

    // console.log($event);
    let selectedId = $event.detail.value;
    this.formGroupSystemSelect.controls['system_id'].setValue(selectedId);
   

  }

  remove(){    
    this.onRemove.emit()
  }
  

 
}
