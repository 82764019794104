import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'board-lodging',
  templateUrl: './board-lodging.component.html',
  styleUrls: ['./board-lodging.component.scss'],
})
export class BoardLodgingComponent implements OnInit {

  // public haveLunch : boolean = false; // ha pranzato
  // public haveDinner : boolean = false; // ha cenato
  // public haveStayedOvernight : boolean = false; // ha pernottato

  @Input()
  public formGroup: FormGroup;

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroup.controls; }

  @Input()
  public edit: boolean = true;

  public isAgreed: boolean = false;

  constructor() { }

  ngOnInit() {

    this.isAgreed = this.formGroup.controls['is_agreed'].value || false
    // console.log('is_agreed', this.isAgreed);

    

    // this.formGroup.valueChanges.subscribe(data =>{
    //   console.log(data);
    //   console.log(this.formGroup);
      
    // });
    
    this.formGroup.controls['is_agreed'].valueChanges.subscribe(data =>{
      // console.log('is_agreed', data);
      
      // console.log(this.formGroup);
      

    //   // console.log(this.movTypeIsSelected , this.movTypeIsSelected);

    //   // if(this.itemIsSelected && this.siteIsSelected && this.movTypeIsSelected){
    //   //   this.formGroup.controls['qty'].enable()
    //   // } else {
    //   //   this.formGroup.controls['qty'].disable();
    //   // }
      this.updateDescriptionRequired();
    })

    
    this.updateDescriptionRequired();
  }

  public async updateDescriptionRequired(){



    this.isAgreed = this.formGroup.controls['is_agreed'].value === true
    // console.log('isAgreed', this.isAgreed);

    this.formGroup.get('activity_name').clearValidators();

    if(this.isAgreed){
      this.formGroup.get('activity_name').setValidators([Validators.required])
    }

    this.formGroup.get('activity_name').updateValueAndValidity()
  }


  public onSelectChange($event: any) {


    let inputValue = $event.detail.value;
    let inputName = $event.target.control;
    
    this.formGroup.controls['type'].setValue(inputValue);
  }


  // get is_agreed() {
  //   return this.formGroup.get('is_agreed').value;
  // }
  
  // set is_agreed(ev: CustomEvent) {
  //   this.formGroup.get('is_agreed').patchValue(ev.detail.value);
  // }
  

  // public onAgreedChange($event: any) {

  //   console.log("onAgreedChange", $event);   

  //   let inputValue = $event.detail.value;   
    
  //   this.formGroup.controls['is_agreed'].setValue(inputValue);

  //   this.updateDescriptionRequired();
  // }

 

  remove() {
    this.onRemove.emit()
  }

}
