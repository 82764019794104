import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SubSite } from '../class/sub-site.class';

@Injectable({
  providedIn: 'root'
})
export class SubSiteService {

  constructor(private http: HttpClient) { 
    
  }

  getSelect(siteId : string) {
    
    return this.http.get<Array<SubSite>>(`${environment.api_site}/sub-site/select/${siteId}`);    

  }

  getAll(s) {
    
    return this.http.get<Array<SubSite>>(`${environment.api_site}/sub-site`);    

  }

  getAllByQuery(queries : object) : Observable<Array<SubSite>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);

    return this.http.get<Array<SubSite>>(`${environment.api_site}/sub-site`, { params });  
  }

  getById(id: string) {
    
    return this.http.get<SubSite>(`${environment.api_site}/sub-site/${id}`);    

  }

  insert(data: SubSite) {
    
    return this.http.post<SubSite>(`${environment.api_site}/sub-site/`, data);    

  }

  update(data: SubSite) {
    
    return this.http.put<SubSite>(`${environment.api_site}/sub-site/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/sub-site/${id}`);    

  }

}
