import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TableComponent } from './components/table/table.component';
import { FilterPipe } from './pipe/filter.pipe';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SiteSelectComponent } from './components/site-select/site-select.component';
import { SystemSelectComponent } from './components/system-select/system-select.component';
import { ActivitySiteTimeComponent } from './components/activity-site-time/activity-site-time.component';
import { BoardLodgingComponent } from './components/board-lodging/board-lodging.component';
import { FuelComponent } from './components/fuel/fuel.component';
import { ItemMovment } from './class/item-movment.class';
import { ItemMovmentComponent } from './components/item-movment/item-movment.component';
import { RolePermissionComponent } from './components/role-permission/role-permission.component';
import { DdtTransportComponent } from './components/ddt-transport/ddt-transport.component';
import { MovmentRowComponent } from './components/movment-row/movment-row.component';
import { SiteReportComponent } from './components/site-report/site-report.component';
import { ItemMainteneanceComponent } from './components/item-mainteneance/item-mainteneance.component';
import { ModalSiteComponent } from './components/modal-site/modal-site.component';
import { IonicModule } from '@ionic/angular';

@NgModule({  
  imports: 
  [ 
    IonicModule,
    FormsModule,
    CommonModule,
    FontAwesomeModule,
    NgxDatatableModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsKey,
      libraries: ['places', 'drawing', 'geometry']
    }),
    NgxChartsModule
  ],
  declarations: [
    TableComponent,
    FilterPipe,
    SiteSelectComponent,
    SystemSelectComponent,
    ActivitySiteTimeComponent,
    BoardLodgingComponent,
    FuelComponent,
    ItemMovmentComponent,
    RolePermissionComponent,
    DdtTransportComponent,
    MovmentRowComponent,
    SiteReportComponent,
    ItemMainteneanceComponent,
    ModalSiteComponent
    //AddressComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TableComponent,
    SiteSelectComponent,
    SystemSelectComponent,
    ActivitySiteTimeComponent,
    BoardLodgingComponent,
    FuelComponent,
    ItemMovmentComponent,
    RolePermissionComponent,
    DdtTransportComponent,
    MovmentRowComponent,
    SiteReportComponent,
    ItemMainteneanceComponent,
    ModalSiteComponent,
    //AddressComponent,
    FontAwesomeModule,
    CommonModule,
    AgmCoreModule,
    NgxDatatableModule,
    NgxChartsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule {}
