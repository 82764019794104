import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';

@Injectable({
  providedIn: 'root'
})
export class WorkReportService {

  constructor(private http: HttpClient) { 
    
  }

  // getSelect() {
    
  //   return this.http.get<Array<any>>(`${environment.api_site}/site/select`);    

  // }

  // getAll() {
    
  //   return this.http.get<Array<Site>>(`${environment.api_site}/site`);    

  // }

  getAllByQuery(queries : object) : Observable<Array<WorkReport>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    // console.log("params",params);

    return this.http.get<Array<WorkReport>>(`${environment.api_site}/work-report`, { params });  
  }

  getById(id: string) {
    
    return this.http.get<WorkReport>(`${environment.api_site}/work-report/${id}`);    

  }

  insert(data: WorkReport) {
    
    return this.http.post<WorkReport>(`${environment.api_site}/work-report/`, data);    

  }

  close(id: string) {
    
    return this.http.post<any>(`${environment.api_site}/work-report/${id}/close`, {});    

  }

  update(data: WorkReport) {
    
    return this.http.put<WorkReport>(`${environment.api_site}/work-report/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/work-report/${id}`);    

  }

}
