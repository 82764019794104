import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Company } from '../class/company.class';
import { System } from '../class/system.class';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(private http: HttpClient) { 
    
  }
  
  getSelect() {
    
    return this.http.get<Array<System>>(`${environment.api_site}/system/select`);    

  }

  getAllByQuery(queries : object) : Observable<Array<System>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);
    
    return this.http.get<Array<System>>(`${environment.api_site}/system`, { params });    

  }

  getById(id: string) {
    
    return this.http.get<System>(`${environment.api_site}/system/${id}`);    

  }

  insert(data: System) {
    
    return this.http.post<System>(`${environment.api_site}/system/`, data);    

  }

  update(data: System) {
    
    return this.http.put<System>(`${environment.api_site}/system/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/system/${id}`);    

  }
}
