import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ItemMatrix, ItemMatrixTypeEnum, ItemStock } from '../../class/item.class';
import { WorkActivity } from '../../class/work-activity.class';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { HelperService } from '../../services/helper.service';
import { ItemMatrixService } from '../../services/item-matrix.service';
import { ItemService } from '../../services/item.service';
import { SiteService } from '../../services/site.service';
import { SystemService } from '../../services/system.service';
import { UserService } from '../../services/user.service';
import { WorkActivityService } from '../../services/work-activity.service';
import { WorkReportService } from '../../services/work-report.service';
import { SiteReport } from '../../class/site-report.class';
import { Site } from '../../class/site.class';
import { SubSite } from '../../class/sub-site.class';
import { SubSiteService } from '../../services/sub-site.service';
import { ModalSiteComponent } from '../modal-site/modal-site.component';

@Component({
  selector: 'site-report',
  templateUrl: './site-report.component.html',
  styleUrls: ['./site-report.component.scss'],
})
export class SiteReportComponent implements OnInit {

  @Input()
  public formGroupSiteReport: FormGroup;

  @Input()
  public edit: boolean = false;


  public selected_site_id: string;

  public selected_site : Site


  @Input()
  public siteReport: SiteReport;


  public sites_select: Array<Site> = []

  public sub_sites_select: Array<SubSite> = []

  public systems_select: Array<any> = [];




  public users_select: Array<any> = [];

  public work_activity_select: Array<any> = [];

  public work_activity_list: Array<WorkActivity> = [];

  public time_slot: Array<any> = [];

  // public listItemMatrixInLength: Array<ItemMatrix> = []
  // public listItemMatrixInType: Array<ItemMatrix> = []

  // public listItemMatrixOutLength: Array<ItemMatrix> = []
  // public listItemMatrixOutType: Array<ItemMatrix> = []


  public listItemMatrixLength: Array<ItemMatrix> = []
  public listItemMatrixType: Array<ItemMatrix> = []


  public listItemPalancoleIn: Array<ItemStock> = []
  public listItemPalancoleOut: Array<ItemStock> = []


  //usato per mostrare il tempo
  public displayTime: string;
  public displayTimeSelectedTotal: string;

  public displayTimeForChart: number = 0;
  public displayTimeSelectedTotalForChart: number = 0;

  // il totale delle somme del tempo nelle tabelle attivitò
  public activitySiteTimeSelectedTotal: any = moment.duration();

  public palancole_insert_html: boolean = false;
  public palancole_extraction_html: boolean = false;
  public transport_html: boolean = false;
  public mainteneance_html: boolean = false;
  


  @Output()
  public onSiteRemove = new EventEmitter<FormGroup>();

  @Output()
  public onSiteSelectEvent = new EventEmitter<string>();



  constructor(public helperService: HelperService,
    public authService: AuthService,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private siteService: SiteService,
    private subSiteService: SubSiteService,
    private systemService: SystemService,
    private wActivityService: WorkActivityService,
    private wrService: WorkReportService,
    private itemService: ItemService,
    private itemMatrixService: ItemMatrixService) {

    // this.formGroup = this.formBuilder.group({
    //   site_id : new FormControl(),
    //   system_ids: new FormArray([]),
    //   time_a_start: new FormControl([, Validators.required]),
    //   time_a_stop: new FormControl([, Validators.required]),
    //   time_b_start: new FormControl(),
    //   time_b_stop: new FormControl(),
    //   time_total : new FormControl(),
    //   activity_site_time: new FormArray([]),
    //   item_movments: new FormArray([]),
    //   customer_items : new FormControl(),
    //   ddt_transport: new FormArray([]),
    //   fuels: new FormArray([]),
    //   employee_onsite: new FormControl(),
    //   note: new FormControl(),
    // });

    this.generateTimeSlot();


    

  }

  async ngOnInit() {

    

    await this.siteService.getSelect().toPromise().then(data => {
      this.sites_select = data;
    });
    

    await this.systemService.getSelect().toPromise().then(data => {
      this.systems_select = data;
    });

    await this.userService.getSelect().toPromise().then(data => {
      this.users_select = data;
    });

    await this.wActivityService.getSelect().toPromise().then(data => {
      this.work_activity_select = data;
    });

    await this.wActivityService.getAll().toPromise().then(data => {
      this.work_activity_list = data
    });

    this.formGroupSiteReport.valueChanges.subscribe(async (data) => {
      await this.updateActivitySiteTimeSelected();
    })

    // console.log(this.formGroupSiteReport.controls);
    this.generateActivityForm();

    this.formGroupSiteReport.get('activity_site_time').valueChanges.subscribe(async data => {

      // console.log('activity_site_time valueChanges', data);


      // attivo e disattivo le areee interessate dalle attività    
      for (const activityForm of data) {

        let activity = this.work_activity_list.find(a => a.id === activityForm.activity_id)

        // console.log("activity", activity);
        // console.log("activity.trigger_html_area", activity.trigger_html_area);
        
        if (activityForm?.time?.length > 0) {

          // console.log("activity", activity);

          if (activity.trigger_html_area) {
            this[activity.trigger_html_area] = true;

            // aggiorno la disponibilità dellepalancole sul cantiere /sito
            
            this.getSitePalancoleStock(activity?.movment_type?.code)    
            
            

            // break
          }

        } else {
          if (activity.trigger_html_area) {
            this[activity.trigger_html_area] = false;
          }
        }



      }

    });
    


    // console.log(this.siteReport);


    // 
    // setTimeout(()=>{

      if(this.siteReport){

        this.formGroupSiteReport.controls['id'].setValue(this.siteReport.id);

        this.formGroupSiteReport.controls['site_id'].setValue(this.siteReport.site_id);
        this.selected_site_id = this.siteReport.site_id
        this.setSiteName()

        this.formGroupSiteReport.controls['time_a_start'].setValue(this.siteReport.time_a_start);
        this.formGroupSiteReport.controls['time_a_stop'].setValue(this.siteReport.time_a_stop);

        this.formGroupSiteReport.controls['time_b_start'].setValue(this.siteReport.time_b_start);
        this.formGroupSiteReport.controls['time_b_stop'].setValue(this.siteReport.time_b_stop);

        this.formGroupSiteReport.controls['time_total'].setValue(this.siteReport.time_total);

        // this.formGroupSiteReport.controls['item_movments'].setValue(this.siteReport.item_movments);

        this.formGroupSiteReport.controls['customer_items'].setValue(this.siteReport.customer_items);

        this.formGroupSiteReport.controls['employee_onsite'].setValue(this.siteReport.employee_onsite);

        this.formGroupSiteReport.controls['note'].setValue(this.siteReport.note);

        this.formGroupSiteReport.controls['report_id'].setValue(this.siteReport.work_report_id);

      }
      
    // },1000)


    

    
    // this.insert_extraction_html = this.siteReport?.activity_site_time?.length > 0
    
    if(this.siteReport?.activity_site_time){   
      
      
      
      // fix nel caso vengano aggiunte attività in seguito 
      for (const aFControl of this.activity_site_time.controls){

        let activityExist = this.siteReport?.activity_site_time.find(a => a.activity_id == aFControl.get('activity_id').value)

        if(!activityExist){

          this.siteReport?.activity_site_time.push({     
            id : null,     
            activity_id : aFControl.get('activity_id').value,
            site_id : this.selected_site_id,
            time : null
          })

        }

      }



      // console.log(this.siteReport?.activity_site_time);
      // console.log(this.activity_site_time);


      // setTimeout(()=>{
      this.activity_site_time.setValue(this.siteReport.activity_site_time);
      // },1000)

    }


    if(this.siteReport?.ddt_transport){

      this.transport_html = this.siteReport?.ddt_transport?.length > 0
      
      for (const iterator of this.siteReport?.ddt_transport) {
        this.addDDTTransportToReport();
      }
      // setTimeout(()=>{
        this.ddt_transport.setValue(this.siteReport?.ddt_transport);
      // },1000)
    }



    if(this.siteReport?.system_ids){
      for (const iterator of this.siteReport?.system_ids) {
        this.addSystemToReport();
      }
      if(this.siteReport?.system_ids.length > 0){
        // setTimeout(()=>{
          this.system_ids.setValue(this.siteReport.system_ids);
        // },1000)
      }
    }


    if(this.siteReport?.fuels){
      

      for (const iterator of this.siteReport?.fuels) {
        this.addFuelToReport();
      }
      if(this.siteReport?.fuels.length > 0){
        // setTimeout(()=>{
           this.formGroupSiteReport.controls['fuels'].setValue(this.siteReport.fuels);
        // },1000)
      }
    }
    

    if(this.siteReport?.mainteneance_logs){

      this.mainteneance_html = this.siteReport?.mainteneance_logs.length > 0

      for (const iterator of this.siteReport?.mainteneance_logs) {
        this.addMainteneanceLogToReport();
      }
      if(this.siteReport?.mainteneance_logs.length > 0){
        // setTimeout(()=>{
          this.formGroupSiteReport.controls['mainteneance_logs'].patchValue(this.siteReport.mainteneance_logs);
        // },1100)
      }
    }

    // this.formGroupSiteReport.get('site_id').valueChanges.subscribe(async data => {

      // console.log(data);
      

    //   console.log(this.formGroupSiteReport.controls['site_id'].value);
    

    //   if(this.formGroupSiteReport.controls['site_id'].value){
    //     this.subSiteService.getSelect(this.selected_site_id).toPromise().then(data => {
    //       this.sub_sites_select = data;      

    //       if(this.formGroupSiteReport.controls['sub_site_id'].value){
    //         setTimeout(()=>{
    //           console.log("set sub site");
              
    //           this.formGroupSiteReport.controls['sub_site_id'].setValue(this.formGroupSiteReport.controls['sub_site_id'].value);
    //         },100)
    //       }
    //     })
    //   }

    // });
    
    

    

    


    

  }



  get f() { return this.formGroupSiteReport.controls; }

  public get ddt_transport() {
    return this.formGroupSiteReport.get('ddt_transport') as FormArray;
  }

  public get item_movments_insert() {
    return this.formGroupSiteReport.get('item_movments_insert') as FormArray;
  }

  public get item_movments_extraction() {
    return this.formGroupSiteReport.get('item_movments_extraction') as FormArray;
  }

  public get fuels() {
    return this.formGroupSiteReport.get('fuels') as FormArray;
  }

  public get system_ids() {
    return this.formGroupSiteReport.get('system_ids') as FormArray;
  }

  public get activity_site_time() {
    return this.formGroupSiteReport.get('activity_site_time') as FormArray;
  }

  public get mainteneance_logs() {
    return this.formGroupSiteReport.get('mainteneance_logs') as FormArray;
  }



  // public get site_id() {
  //   return this.formGroup.controls['site_id'].value || null
  // }



  onSiteSelect($event: any) {

    // console.log('site select',$event, this.siteReport?.sub_site_id);
    

    this.selected_site_id = $event.detail.value;
    this.formGroupSiteReport.controls['site_id'].setValue(this.selected_site_id);

    this.generateActivityForm()

    this.setSiteName()
    // this.site_name = 

    this.subSiteService.getSelect(this.selected_site_id).toPromise().then(data => {
      this.sub_sites_select = data;

      if(this.siteReport?.sub_site_id){
        setTimeout(() => {
          this.formGroupSiteReport.controls['sub_site_id'].setValue(this.siteReport.sub_site_id);  
        });
        
      }
      
    })

    this.onSiteSelectEvent.emit(this.selected_site_id)

    this.getPalancoleTypeOnSite();

  }

  onSubSiteSelect($event: any) {

    let selectedId = $event.detail.value;
    this.formGroupSiteReport.controls['sub_site_id'].setValue(selectedId);

  }

  async removeSitesFromReport() {

    let textMessage = 'Vuoi rimuovere il rapportino?'

    if(this.selected_site_id){
      textMessage = `Vuoi rimuovere il rapportino di ${this.selected_site.name}?`
    }

    const alert = await this.alertController.create({
      header: textMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {

            this.onSiteRemove.emit()

            this.generateActivityForm()

          },
        },
      ],
    });

    await alert.present();

  }


  addDDTTransportToReport() {

    const group = new FormGroup({
      id: new FormControl(),
      ddt_number: new FormControl(null, Validators.required),
      description: new FormControl()
    });

    this.ddt_transport.push(group);

  }

  async removeDDTTransportReport(index: number) {


    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere il ddt?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {

            this.ddt_transport.removeAt(index);
          },
        },
      ],
    });

    await alert.present();

  }

  addMainteneanceLogToReport() {

    const group = new FormGroup({
      id: new FormControl(),
      site_report_id: new FormControl(),
      system_id: new FormControl(null, Validators.required),      
      description: new FormControl(),
      mainteneance_predictive_id: new FormControl(),
      mainteneance_preventive_id: new FormControl(null, Validators.required),
      created_at: new FormControl(),
      updated_at: new FormControl(),
      deleted_at: new FormControl()
    });

    this.mainteneance_logs.push(group);

  }

  async removeMainteneanceLogReport(index: number) {


    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere la manutenzione?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {

            this.mainteneance_logs.removeAt(index);
          },
        },
      ],
    });

    await alert.present();

  }

  addFuelToReport() {

    const group = new FormGroup({
      id: new FormControl(null),
      type: new FormControl(null, Validators.required),
      system_id: new FormControl(null, Validators.required),
      origin: new FormControl(null, Validators.required),
      qty: new FormControl(null, Validators.required)
    });

    this.fuels.push(group);

  }

  async removeFuelReport(index: number) {


    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {

            this.fuels.removeAt(index);
          },
        },
      ],
    });

    await alert.present();

  }

  addSystemToReport() {

    const group = new FormGroup({
      system_id: new FormControl('', Validators.required)
    });

    this.system_ids.push(group);

  }

  async removeSystemFromReport(index: number) {


    const alert = await this.alertController.create({
      header: 'Vuoi rimuovere il mezzo?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {

          },
        },
        {
          text: 'OK',
          role: 'confirm',
          handler: () => {

            this.system_ids.removeAt(index);
          },
        },
      ],
    });

    await alert.present();

  }

  addActivitySiteTimeToReport(activity_id: string) {   

    let group = new FormGroup({
      id: new FormControl(),
      activity_id: new FormControl(activity_id, Validators.required),
      site_id: new FormControl(this.selected_site_id, Validators.required),
      time: new FormControl(null),
    });

    this.activity_site_time.push(group);

  }

  getActivityFormControl(activity_id: string) {

    let form = this.activity_site_time.controls.find(control =>
      control.get('activity_id').value === activity_id &&
      control.get('site_id').value === this.selected_site_id
    )

    if (!form) {
      form = new FormGroup({
        id: new FormControl(),
        activity_id: new FormControl(),
        site_id: new FormControl(),
        time: new FormControl(),
      });
    }


    return form
  }

  generateActivityForm() {

    this.activity_site_time.clear()

    for (const activity of this.work_activity_list) {
      this.addActivitySiteTimeToReport(activity.id);
    }

  }

  getMatrixFormControl(movmentType: string, itemLengthCode: string, itemTypeCode: string): AbstractControl {
    
    if(movmentType == 'inserted'){
      return this.item_movments_insert?.controls.find(control =>
        control.get('length_code').value === itemLengthCode &&
        control.get('type_code').value === itemTypeCode
      )
    }
    
    if(movmentType == 'extracted'){
      return this.item_movments_extraction?.controls.find(control =>
        control.get('length_code').value === itemLengthCode &&
        control.get('type_code').value === itemTypeCode
      )  
    }
    
  }

  // evento generato solo per aggiornare la quantità disponibile intradocumento
  updatePalancoleMatrixIn($event : any){
    console.log("updatePalancoleMatrixIn", $event);

    // non posso estrarre più palancole di quelle già infisse
    // aggiorno il numero di palancole  infisse in cantiere

    //trovo il controllo
    let id = $event.value["id"]
    let length_code = $event.value["length_code"]
    let type_code = $event.value["type_code"]
    let qty = Number($event.controls["qty"].value) * -1
    
    let controlIndex = this.item_movments_extraction.controls
      .findIndex(c => c.get("length_code").value == length_code
        && c.get("type_code").value == type_code)

    if(controlIndex > -1){
      // aggiorno la disponibilità di palancole da infissare
      let controlAvailableValue = Number(this.listItemPalancoleOut.find(p => p.id == id)?.available) || 0
      console.log(controlAvailableValue);
      console.log(qty);
      console.log(controlAvailableValue + qty);
      
      this.item_movments_extraction.controls[controlIndex].get("available").patchValue(controlAvailableValue + qty)
      
    }
    
  }

  updatePalancoleMatrixOut($event : FormGroup){
    // console.log("updatePalancoleMatrixOut", $event);

    // // non posso estrarre più palancole di quelle già infisse
    // // aggiorno il numero di palancole non infisse in cantiere

    // //trovo il controllo
    // let id = $event.value["id"]
    // let length_code = $event.value["length_code"]
    // let type_code = $event.value["type_code"]
    // let qty = Number($event.controls["qty"].value)
    
    // let controlIndex = this.item_movments_insert.controls
    //   .findIndex(c => c.get("length_code").value == length_code
    //     && c.get("type_code").value == type_code)

    // if(controlIndex > -1){
    //   // aggiorno la disponibilità di palancole da infissare
    //   let controlAvailableValue = Number(this.listItemPalancoleIn.find(p => p.id == id)?.available) || 0
    //   console.log(controlAvailableValue);
    //   console.log(qty);
    //   console.log(controlAvailableValue + qty);
      
    //   this.item_movments_insert.controls[controlIndex].get("available").patchValue(controlAvailableValue + qty)
      
    // }


  }




  addItemMovmentToReport(movmentType: string, item: ItemStock) { // itemLengthCode : string, itemTypeCode : string, ){

    // console.log("addItemMovmentToReport", item);


    let group = new FormGroup({
      id: new FormControl(item.id),
      code: new FormControl(item.code),
      length_code: new FormControl(item.length_code),
      type_code: new FormControl(item.type_code),
      available: new FormControl(item.available || 0),
      movment_type : new FormControl(null),
      // qty: new FormControl({ value : 0 , disabled : false}, [Validators.required,Validators.min(1), Validators.max(available)]),
      qty: new FormControl({ value: item.qty, disabled: false }, [Validators.required, Validators.min(0)]),
      // available: new FormControl({ value : 0 , disabled : true}),
      // item_id: new FormControl(null, Validators.required),
      // work_report_id: new FormControl(),      
      // qty: new FormControl({ value : 0 , disabled : true}, [Validators.required,Validators.min(0)]),
      // description: new FormControl(),
      // site_id: new FormControl(null, Validators.required),
      // movment_type_code: new FormControl(null, Validators.required),
      created_at: new FormControl(),
      updated_at: new FormControl(),
      deleted_at: new FormControl()
    });

    if (item.available > 0) {
      group.controls["qty"].clearValidators()
      group.controls["qty"].addValidators([Validators.required, Validators.min(0), Validators.max(item.qty)])
      group.controls["qty"].updateValueAndValidity()
    }


    if(movmentType == 'inserted'){
      this.item_movments_insert.push(group);
    }

    if(movmentType == 'extracted'){
      this.item_movments_extraction.push(group);
    }
    // this.item_movments.push(group);

  }

  




  async getPalancoleTypeOnSite() {

    // console.log("getPalancoleTypeOnSite", this.selected_site_id, status);

    if(!this.selected_site_id) return

    await this.getSitePalancoleStock();

    let allPalancoleTypeAvailableOnSite = await this.itemService.getPalancoleTypeOnSite(this.selected_site_id).toPromise()
    console.log("allPalancoleTypeAvailableOnSite",allPalancoleTypeAvailableOnSite);
    

    // lista code
    let allLengthCode = [...new Set(allPalancoleTypeAvailableOnSite.map(t => t.length_code))]
    let temp_listItemMatrixLength = await this.itemMatrixService.getAll(ItemMatrixTypeEnum.length).toPromise()
    this.listItemMatrixLength = temp_listItemMatrixLength.filter(i => allLengthCode.includes(i.code))   


    // lista type
    let allTypeCode = [...new Set(allPalancoleTypeAvailableOnSite.map(t => t.type_code))]  
    let temp_listItemMatrixType = await this.itemMatrixService.getAll(ItemMatrixTypeEnum.type).toPromise()
    this.listItemMatrixType = temp_listItemMatrixType.filter(i => allTypeCode.includes(i.code))
    
    // console.log(this.listItemMatrixLength,this.listItemMatrixType);
    
   
    this.item_movments_insert.clear()
    this.item_movments_extraction.clear()

    let total = this.listItemMatrixLength.length * this.listItemMatrixType.length

    // in
    for (let i = 0; i < total; i++) {  

      // item per popolare l'array
      let itemStock = new ItemStock()
      itemStock.name = allPalancoleTypeAvailableOnSite[i].name 
      itemStock.id = allPalancoleTypeAvailableOnSite[i].id      
      itemStock.length_code = allPalancoleTypeAvailableOnSite[i].length_code
      itemStock.type_code = allPalancoleTypeAvailableOnSite[i].type_code
      itemStock.available = this.listItemPalancoleIn.find(p => p.id == allPalancoleTypeAvailableOnSite[i].id )?.available || 0   
      
      let qty = Number(this.siteReport?.item_movments.find(p => 
        p.movment_type_code == 'extracted' &&
        p.item_id == allPalancoleTypeAvailableOnSite[i].id)?.qty) || 0

      itemStock.qty = qty
      

      await this.addItemMovmentToReport('extracted',itemStock);
    }

    // console.log(this.siteReport?.movment?.movment_rows);

    // out
    for (let i = 0; i < total; i++) {  

      // item per popolare l'array
      let itemStock = new ItemStock()
      itemStock.name = allPalancoleTypeAvailableOnSite[i].name 
      itemStock.id = allPalancoleTypeAvailableOnSite[i].id      
      itemStock.length_code = allPalancoleTypeAvailableOnSite[i].length_code
      itemStock.type_code = allPalancoleTypeAvailableOnSite[i].type_code      
      itemStock.available = this.listItemPalancoleOut.find(p => p.id == allPalancoleTypeAvailableOnSite[i].id )?.available || 0

      let qty = Number(this.siteReport?.item_movments.find(p => 
        p.movment_type_code == 'inserted' &&
        p.item_id == allPalancoleTypeAvailableOnSite[i].id)?.qty) || 0

      itemStock.qty = qty
      

      await this.addItemMovmentToReport('inserted',itemStock);

    }

    
  }

  async getSitePalancoleStock(status: string = null) {

    // console.log("getSitePalancoleStock", this.selected_site_id, status);

    if(!this.selected_site_id) return


    this.listItemPalancoleIn = await this.itemService.getSitePalancole(this.selected_site_id, 'inserted').toPromise()
    // await this.generateItemMovmentMatrixForm('inserted');
      
    this.listItemPalancoleOut = await this.itemService.getSitePalancole(this.selected_site_id, 'extracted').toPromise() 
    // await this.generateItemMovmentMatrixForm('extracted');

    //console.log("this.listItemPalancoleIn",this.listItemPalancoleIn);
    //console.log("this.listItemPalancoleOut",this.listItemPalancoleOut);

  }

  // in o out
  async generateItemMovmentMatrixForm(movmentType : string) {

    // console.log("generateItemMovmentMatrixForm", movmentType);

    // console.log("this.listItemPalancoleIn",this.listItemPalancoleIn);
    // console.log("this.listItemPalancoleOut",this.listItemPalancoleOut);
    
    // console.log(this.siteReport);
    


    // if(movmentType == 'inserted'){
    //   // pulisco l'array dei form
    //   // this.item_movments_insert.clear()

    //   // ricreo l'array
    //   // for (const item of this.listItemPalancoleIn) {
    //   //   await this.addItemMovmentToReport('inserted',item);       
    //   // }

    //   // setTimeout(() => {

    //     // NOTE Fix formato input dati
    //     let item_movments = Array.from(this.siteReport?.movment?.movment_rows, (i) => {
    //       let itemStock = new ItemStock()
    //       itemStock.id = i.item.id
    //       itemStock.code = i.item.code
    //       itemStock.available = this.listItemPalancoleIn.find(p => p.id == i.item.id).available || 0
    //       itemStock.qty = i.qty
    //       itemStock.length_code = i.item.item_matrix_length.code
    //       itemStock.type_code = i.item.item_matrix_type.code
    //       itemStock.created_at = i.created_at
    //       itemStock.updated_at = i.updated_at
    //       itemStock.deleted_at = i.deleted_at

    //       return itemStock
    //     })

    //     console.log("item_movments", item_movments);

    //     if (item_movments) {
    //       for (const item of item_movments) {

    //         //trovo il controllo
    //         let controlIndex = this.item_movments_insert.controls
    //                                 .findIndex(c => c.get("length_code").value == item.length_code
    //                                 && c.get("type_code").value == item.type_code)

    //         if(controlIndex > -1){
    //           this.item_movments_insert.controls[controlIndex].patchValue(item)
    //         }

    //       }
    //       // this.formGroupSiteReport.controls['item_movments_insert'].patchValue(item_movments);
    //     }
    //   // }, 1000)

    //   //-----
    // }


    // if(movmentType == 'extracted'){
    //   // pulisco l'array dei form      
    //   // this.item_movments_extraction.clear()

    //   // ricreo l'array
    //   // for (let index = 0; index < (this.listItemMatrixLength.length * this.listItemMatrixType.length); index++) {
    //   //   await this.addItemMovmentToReport('extracted',null);
        
    //   // }
    //   // for (const item of (this.listItemMatrixLength.length * this.listItemMatrixType.length)) {
    //   //   await this.addItemMovmentToReport('extracted',item);
       
    //   // }

    //   // setTimeout(() => {

    //     // NOTE Fix formato input dati
    //     let item_movments = Array.from(this.siteReport?.movment?.movment_rows, (i) => {
    //       let itemStock = new ItemStock()
    //       itemStock.id = i.item.id
    //       itemStock.code = i.item.code
    //       itemStock.available = this.listItemPalancoleOut.find(p => p.id == i.item.id).available || 0
    //       itemStock.qty = i.qty
    //       itemStock.length_code = i.item.item_matrix_length.code
    //       itemStock.type_code = i.item.item_matrix_type.code
    //       itemStock.created_at = i.created_at
    //       itemStock.updated_at = i.updated_at
    //       itemStock.deleted_at = i.deleted_at

    //       return itemStock
    //     })

    //     // console.log(item_movments);

    //     if (item_movments) {

    //       for (const item of item_movments) {

    //         //trovo il controllo
    //         let controlIndex = this.item_movments_extraction.controls
    //                                 .findIndex(c => c.get("length_code").value == item.length_code
    //                                 && c.get("type_code").value == item.type_code)

    //         if(controlIndex > -1){
    //           this.item_movments_extraction.controls[controlIndex].patchValue(item)
    //         }

    //       }

    //       // this.formGroupSiteReport.controls['item_movments_extraction'].patchValue(item_movments);
    //     }
    //   // }, 1000)


    //   // console.log("---------------");      
    //   // console.log(this.listItemMatrixInType.length,this.listItemMatrixInLength.length);
    //   // console.log(this.listItemMatrixOutType.length,this.listItemMatrixOutLength.length);
      



    // }
    

    

   

  }


  public async updateActivitySiteTimeSelected(): Promise<any> {

    this.activitySiteTimeSelectedTotal = moment.duration();

    for (let index = 0; index < this.activity_site_time.controls.length; index++) {
      const fg: FormGroup = this.activity_site_time.controls[index] as FormGroup;



      let timeValue = fg.controls['time'].value

      if (timeValue) {

        let time = moment.duration();

        time.add(timeValue.toString().split(':')[0], 'hour');
        time.add(timeValue.toString().split(':')[1], 'minute');

        this.activitySiteTimeSelectedTotal.add(time);



        this.displayTimeSelectedTotal = moment.utc(this.activitySiteTimeSelectedTotal.as('milliseconds')).format('HH:mm')

        this.displayTimeSelectedTotalForChart = this.activitySiteTimeSelectedTotal.asHours()

      }

      // let controlsInForm = Object.keys(fg.controls);

      // for (const controlName of controlsInForm) {
      //   if (controlName !== 'activity_id' && fg.controls[controlName].value !== '') {
      //     // verifico il valore
      //     let timeValue = fg.controls[controlName].value

      //     // console.log(timeValue);


      //     let time = moment.duration();

      //     time.add(timeValue.toString().split(':')[0] ,'hour');
      //     time.add(timeValue.toString().split(':')[1] ,'minute');



      //     this.activitySiteTimeSelectedTotal.add(time);

      //     // console.log(time);
      //     // console.log(this.activitySiteTimeSelectedTotal);


      //     this.displayTimeSelectedTotal = moment.utc(this.activitySiteTimeSelectedTotal.as('milliseconds')).format('HH:mm')



      //   }
      // }




    }


  }

  public async updateTotalTimeSelected(): Promise<any> {

    let time_a_start_value = this.formGroupSiteReport.controls['time_a_start'].value
    let time_a_stop_value = this.formGroupSiteReport.controls['time_a_stop'].value
    let time_b_start_value = this.formGroupSiteReport.controls['time_b_start'].value
    let time_b_stop_value = this.formGroupSiteReport.controls['time_b_stop'].value

    //console.log(this.siteReport?.id);
    

    //console.log(time_a_start_value?.toString());
    //console.log(time_a_stop_value?.toString());
    //console.log(time_b_start_value?.toString());
    //console.log(time_b_stop_value?.toString());
/*
    if(!time_a_start_value){
      time_a_start_value = "00:00"
    }
    if(!time_a_stop_value){
      time_a_stop_value = "00:00"
    }
    if(!time_b_start_value){
      time_b_start_value = "00:00"
    }
    if(!time_b_stop_value){
      time_b_stop_value = "00:00"
    }*/


    let duration_total = moment.duration();

    // let time_a_start = moment.duration();

    // if (time_a_start_value && time_a_start_value?.toString() !== '') {
    //   time_a_start.add(time_a_start_value.toString().split(':')[0], 'hour');
    //   time_a_start.add(time_a_start_value.toString().split(':')[1], 'minute');
    // }


    // let time_a_stop = moment.duration();

    // if (time_a_stop_value && time_a_stop_value?.toString() !== '') {
    //   time_a_stop.add(time_a_stop_value.toString().split(':')[0], 'hour');
    //   time_a_stop.add(time_a_stop_value.toString().split(':')[1], 'minute');
    // }

    if (time_a_start_value &&  time_a_stop_value) {
      let time_a_start = moment(time_a_start_value,"HH:mm:ss Z")
      let time_a_stop = moment(time_a_stop_value,"HH:mm:ss Z")

      if(time_a_stop.isBefore(time_a_start)){
          time_a_stop.add(1,"day");
      }

      let slotADuration = moment.duration(time_a_stop.diff(time_a_start))

      //console.log("slotADuration", slotADuration);

      duration_total.add(slotADuration)
    }



/*
    let time_b_start = moment.duration();

    if (time_b_start_value && time_b_start_value?.toString() !== '') {
      time_b_start.add(time_b_start_value.toString().split(':')[0], 'hour');
      time_b_start.add(time_b_start_value.toString().split(':')[1], 'minute');
    }


    let time_b_stop = moment.duration();

    if (time_b_stop_value && time_b_stop_value?.toString() !== '') {
      time_b_stop.add(time_b_stop_value.toString().split(':')[0], 'hour');
      time_b_stop.add(time_b_stop_value.toString().split(':')[1], 'minute');
    }
    */

    if (time_b_start_value && time_b_stop_value) {
      let time_b_start = moment(time_b_start_value,"HH:mm:ss Z")
      let time_b_stop = moment(time_b_stop_value,"HH:mm:ss Z")

      if(time_b_stop.isBefore(time_b_start)){
          time_b_stop.add(1,"day");
      }

      let slotBDuration = moment.duration(time_b_stop.diff(time_b_start))

      //console.log("slotBDuration", slotBDuration);
      

      duration_total.add(slotBDuration)
    }


    // console.log(time_a_start,time_a_stop,time_b_start,time_b_stop);
    //let time_a_result = time_a_stop.subtract(time_a_start)
    // console.log(time_a_result);

    //let time_b_result = time_b_stop.subtract(time_b_start)
    // console.log(time_b_result);

    //duration_total = time_a_result.add(time_b_result)
    // moment.duration().add(time_a_start).add()

    // console.log(time_a_result.add(time_b_result));
    // console.log(duration_total);

    //console.log("duration_total", moment.utc(duration_total.as('milliseconds')).format('HH:mm'));


    this.displayTime = moment.utc(duration_total.as('milliseconds')).format('HH:mm')

    this.displayTimeForChart = duration_total.asHours()

    this.formGroupSiteReport.controls['time_total'].setValue(this.displayTime);








    return duration_total

  }

  onTimeAStartSelect($event: any) {

    //console.log($event);
    let selectedId = $event.detail.value;
    this.formGroupSiteReport.controls['time_a_start'].setValue(selectedId);

    this.updateTotalTimeSelected();
  }

  onTimeAStopSelect($event: any) {

    // console.log($event);
    let selectedId = $event.detail.value;
    this.formGroupSiteReport.controls['time_a_stop'].setValue(selectedId);

    this.updateTotalTimeSelected();
  }

  onTimeBStartSelect($event: any) {

    // console.log($event);
    let selectedId = $event.detail.value;
    this.formGroupSiteReport.controls['time_b_start'].setValue(selectedId);

    this.updateTotalTimeSelected();
  }

  onTimeBStopSelect($event: any) {

    // console.log($event);
    let selectedId = $event.detail.value;
    this.formGroupSiteReport.controls['time_b_stop'].setValue(selectedId);

    this.updateTotalTimeSelected();
  }


  private generateTimeSlot() {
    this.time_slot = [];
    for (let index = 0; index < 25; index++) {
      this.time_slot.push(`${index}:00`);
      this.time_slot.push(`${index}:30`);
    }

    this.time_slot.pop();
    // this.time_slot.shift()
  }

  async checkTimeSelected(): Promise<boolean> {
    let selected_time = moment.duration();
    selected_time.add(this.displayTime.toString().split(':')[0], 'hour');
    selected_time.add(this.displayTime.toString().split(':')[1], 'minute');

    console.log(this.activitySiteTimeSelectedTotal.subtract(selected_time.hours));

    let result = this.activitySiteTimeSelectedTotal.subtract(selected_time.hours) != moment.duration();

    if (result) {
      const alert = await this.alertController.create({
        header: 'Attenzione le ore selezionate non coincidono con le dichiarate!',
        buttons: [

          {
            text: 'OK',
            role: 'confirm',
            handler: () => {

            },
          },
        ],
      });

      await alert.present();
    }

    return result;
  }


  async getSiteDetails(siteId: string) : Promise<Site> {
    return await this.siteService.getById(siteId).toPromise()
  }

  setSiteName(){
    if(!this.selected_site_id) return

    this.getSiteDetails(this.selected_site_id).then(data =>{
      this.selected_site = data
    })
  }


  async openSiteModal(){

    const modal = await this.modalCtrl.create({
      component: ModalSiteComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {

      this.selected_site_id = data;
      this.formGroupSiteReport.controls['site_id'].setValue(this.selected_site_id);

      this.generateActivityForm()

      this.setSiteName()
      // this.site_name = 

      this.subSiteService.getSelect(this.selected_site_id).toPromise().then(data => {
        this.sub_sites_select = data;

        if(this.siteReport?.sub_site_id){
          setTimeout(() => {
            this.formGroupSiteReport.controls['sub_site_id'].setValue(this.siteReport.sub_site_id);  
          });
          
        }
        
      })

      this.onSiteSelectEvent.emit(this.selected_site_id)

      this.getPalancoleTypeOnSite();
      
    }

  }

}
