import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { environment } from './environments/environment.prod';

if (environment.production) {
  enableProdMode();
}

console.log("environment.production: ", environment.production);
console.log("environment.test: ", environment.test);
console.log("environment.dev: ", environment.dev);


if(environment.production){

  // Sentry.init({
  //   dsn: "https://dc9b4d6262b24e54a28b5985902556d3@o372036.ingest.sentry.io/4504729008668672",
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       tracePropagationTargets: ["bonotto.ioteasy.it"],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //     new Sentry.Replay({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0,
  // });

} else {
  
  // Sentry.init({
  //   dsn: "https://009e89ee33cdfbe5757a3a9e3166260c@o372036.ingest.sentry.io/4506625967849472",
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //       tracePropagationTargets: ["bonotto.ioteasy.it"],
  //     }),
  //     new Sentry.Replay({
  //       maskAllText: false,
  //       blockAllMedia: false,
  //     }),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Session Replay
  //   //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
