import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';
import { WorkActivity } from '../class/work-activity.class';

@Injectable({
  providedIn: 'root'
})
export class WorkActivityService {

  constructor(private http: HttpClient) { 
    
  }

  getSelect() {
    
    return this.http.get<Array<WorkActivity>>(`${environment.api_site}/work-activity/select`);    

  }

  getAll() {
    
    return this.http.get<Array<WorkActivity>>(`${environment.api_site}/work-activity`);    

  }

  

  getById(id: string) {
    
    return this.http.get<WorkActivity>(`${environment.api_site}/work-activity/${id}`);    

  }

  insert(data: WorkActivity) {
    
    return this.http.post<WorkActivity>(`${environment.api_site}/work-activity/`, data);    

  }

  update(data: WorkActivity) {
    
    return this.http.put<WorkActivity>(`${environment.api_site}/work-activity/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/work-activity/${id}`);    

  }

}
