import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';
import { ItemMovment } from '../class/item-movment.class';

@Injectable({
  providedIn: 'root'
})
export class ItemMovmentService {

  constructor(private http: HttpClient) { 
    
  }

  // getSelect() {
    
  //   return this.http.get<Array<any>>(`${environment.api_site}/site/select`);    

  // }

  // getAll() {
    
  //   return this.http.get<Array<Site>>(`${environment.api_site}/site`);    

  // }

  getAllByQuery(queries : object) : Observable<Array<ItemMovment>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    // console.log("params",params);

    return this.http.get<Array<ItemMovment>>(`${environment.api_site}/item-movment`, { params });  
  }

  getById(id: string) {
    
    return this.http.get<ItemMovment>(`${environment.api_site}/item-movment/${id}`);    

  }

  getSotckInSite(item_id: string,site_id: string)  {
    
    return this.http.get<any>(`${environment.api_site}/item-movment/${item_id}/stock/${site_id}`);    

  }

  insert(data: ItemMovment) {
    
    return this.http.post<ItemMovment>(`${environment.api_site}/item-movment/`, data);    

  }

 
  update(data: ItemMovment) {
    
    return this.http.put<ItemMovment>(`${environment.api_site}/item-movment/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/item-movment/${id}`);    

  }

}
