import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { 
    
  }

  // orario ultimo aggiornamento dei siti
  private lastUpdateSites : moment.Moment;

  private readonly SITES = "SITES";

  private set storedSites(sites: Array<any>) {
    if(sites){
      localStorage.setItem(this.SITES, JSON.stringify(sites));
    } else {
      localStorage.removeItem(this.SITES);
    }
  }

  private get storedSites(): any | null {   
    let storedSites = JSON.parse(localStorage.getItem(this.SITES));    
    return storedSites;   
  }

  getSelect() {

    console.log(this.lastUpdateSites, this.lastUpdateSites?.diff(moment(),'hour'));
    

    if(!this.lastUpdateSites || this.lastUpdateSites.diff(moment(),'hour') > 1){

      // console.log("RICHIESTA REMOTA");
      
    
      return this.http.get<Array<any>>(`${environment.api_site}/site/select`).pipe(
        switchMap(async (data) => {
          this.lastUpdateSites = moment();
          this.storedSites = data;
          // console.log("xxxxx",data);
          return data
        }))    

    } else {

      // console.log("RICHIESTA LOCALE");

      // console.log(this.storedSites);
      

      return of(this.storedSites);
    }
  }

  getAll() {
    
    return this.http.get<Array<Site>>(`${environment.api_site}/site`);    

  }

  getAllByQuery(queries : object) : Observable<Array<Site>> {

    let params = new HttpParams();

    for (var key in queries) {
      // console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);

    return this.http.get<Array<Site>>(`${environment.api_site}/site`, { params });  
  }

  getById(id: string) {
    
    return this.http.get<Site>(`${environment.api_site}/site/${id}`);    

  }

  insert(data: Site) {
    
    return this.http.post<Site>(`${environment.api_site}/site/`, data);    

  }

  update(data: Site) {
    
    return this.http.put<Site>(`${environment.api_site}/site/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/site/${id}`);    

  }

}
