import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'ddt-transport',
  templateUrl: './ddt-transport.component.html',
  styleUrls: ['./ddt-transport.component.scss'],
})
export class DdtTransportComponent implements OnInit {

  @Input()
  public formGroupDdtTransport: FormGroup;

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroupDdtTransport.controls; }

  @Input()
  public edit: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private systemService : SystemService) {

      

     }

  async ngOnInit() {

    

  }


  remove() {
    this.onRemove.emit()
  }

}
