export const environment = {
  production: true,
  test: false,
  dev : false,
  api_site: 'https://bonotto.ioteasy.it/api/v1',
  gmapsKey: 'AIzaSyDu5P-OyaK6WrajJ91FHGN7DbiGOzViRDU',
  mqtt: {
		server: 'app.ioteasy.it',
		protocol: "wss",
		port: 8002,
		user: 'bitnami',
		password: '5n8w7epm3PGHrsG'
	}
};
