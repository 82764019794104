import { Company } from './company.class'
import { Base } from "./base.class"

export enum ItemType {
    palancole= "palancole",
    other= "other"
}


export class Item extends Base {
    
    code: string

    name: string

    visible: boolean;


    item_matrix_length : ItemMatrix | null
    item_matrix_length_id: string;

    item_matrix_type : ItemMatrix | null
    item_matrix_type_id: string;


    type: ItemType;
}


export enum ItemMatrixTypeEnum {
    type = "type",
    length = "length"
}

export class ItemMatrix extends Base {

    order : number
    
    code: string

    name: string

    prefix : string

    type : ItemMatrixTypeEnum
}


export class ItemStock extends Base {
    
    code: string

    name: string

    qty : number

    available: number

    length_code : string;

    type_code : string;
}



export class ItemWhareHouse extends Base {
    
    code: string

    name: string

    qty : number

    qty_in : number

    qty_out : number

    available: number

    length_code : string;

    type_code : string;
}