import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MainteneancePreventive } from '../class/mainteneance_preventive.class';

@Injectable({
  providedIn: 'root'
})
export class MainteneancePreventiveService {

  constructor(private http: HttpClient) { 

  }

  getSelect(systemTypeId: string) {
    
    return this.http.get<Array<MainteneancePreventive>>(`${environment.api_site}/mainteneance-preventive/select/${systemTypeId}`);    

  }

  getAllByQuery(queries : object) : Observable<Array<MainteneancePreventive>> {

    let params = new HttpParams();

    for (var key in queries) {
      console.log("key " + key + " has value " + queries[key]);

      if(queries[key]) params = params.append(key, queries[key]);

    }

    console.log("params",params);
    
    
    return this.http.get<Array<MainteneancePreventive>>(`${environment.api_site}/mainteneance-preventive/`,{params});    

  }

  // getAll() {
    
  //   return this.http.get<Array<MainteneancePreventive>>(`${environment.api_site}/mainteneance-preventive`);    

  // }

  getById(id: string) {
    
    return this.http.get<MainteneancePreventive>(`${environment.api_site}/mainteneance-preventive/${id}`);    

  }

  insert(data: MainteneancePreventive) {
    
    return this.http.post<MainteneancePreventive>(`${environment.api_site}/mainteneance-preventive/`, data);    

  }

  update(data: MainteneancePreventive) {
    
    return this.http.put<MainteneancePreventive>(`${environment.api_site}/mainteneance-preventive/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/mainteneance-preventive/${id}`);    

  }

}
