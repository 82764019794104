import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Site } from '../class/site.class';
import { WorkReport } from '../class/work-report.class';
import { WorkActivity } from '../class/work-activity.class';
import { Item } from '../class/item.class';
import { Role } from '../class/role.class';
import { RolePage } from '../class/role-page.class';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { 
    
  }

  getSelect() {
    
    return this.http.get<Array<Role>>(`${environment.api_site}/role/select`);    

  }

  getRolePageSelect() {
    
    return this.http.get<Array<RolePage>>(`${environment.api_site}/role/page/select`);    

  }



  getAll() {
    
    return this.http.get<Array<Role>>(`${environment.api_site}/role`);    

  }

  

  getById(id: string) {
    
    return this.http.get<Role>(`${environment.api_site}/role/${id}`);    

  }

  

  insert(data: Role) {
    
    return this.http.post<Role>(`${environment.api_site}/role/`, data);    

  }

  update(data: Role) {
    
    return this.http.put<Role>(`${environment.api_site}/role/${data.id}`, data);    

  }

  delete(id: string) {
    
    return this.http.delete<any>(`${environment.api_site}/role/${id}`);    

  }

}
