import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss'],
})
export class RolePermissionComponent implements OnInit {

  public create :boolean = false;
  public read :boolean = false;
  public update :boolean = false;
  public delete :boolean = false;

  @Input()
  public formGroup: FormGroup;

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroup.controls; }

  @Input()
  public edit: boolean = true;

  public rolepage_select : Array<any> = []

  constructor(private roleService : RoleService) { }

  ngOnInit() {

    this.roleService.getRolePageSelect().toPromise().then(data => {
      this.rolepage_select = data;
    })

    this.formGroup.controls['create'].valueChanges.subscribe(data =>{
      this.create = data === true
    });
    
    this.formGroup.controls['read'].valueChanges.subscribe(data =>{
      this.read = data === true
    });

    this.formGroup.controls['update'].valueChanges.subscribe(data =>{
      this.update = data === true
    });

    this.formGroup.controls['delete'].valueChanges.subscribe(data =>{
      this.delete = data === true
    });
  }


  onCreateCheck(event: any) {
    this.formGroup.controls['create'].setValue(event.detail.checked);
  }

  onReadCheck(event: any) {
    this.formGroup.controls['read'].setValue(event.detail.checked);
  }

  onUpdateCheck(event: any) {
    this.formGroup.controls['update'].setValue(event.detail.checked);
  }

  onDeleteCheck(event: any) {
    this.formGroup.controls['delete'].setValue(event.detail.checked);
  }

  onRolePageSelect(event: any) {
    let inputValue = event.detail.value;
    let inputName = event.target.control;

    this.formGroup.controls['role_page_id'].setValue(inputValue);

  }
 
  remove() {
    
    this.onRemove.emit()
  }

}
