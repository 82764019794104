import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';


@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {

  transform(list: Array<any> = [], property: Array<string>, filterString: string) {

    if (!list || list.length === 0 || !property || !filterString) {
      return list;
    }

    //console.log(list, property, filterString.toLowerCase());

    let filtered_list: Array<any> = [];

    for (let item of list) {

      //console.log(item);

      for (let prop of property) {

        //console.log("prop",prop);

        let property_value = get(item, prop).toLowerCase();

        //console.log("value", property_value);
        

        if (property_value.includes(filterString.toLowerCase())) {
          filtered_list.push(item);
        }
      }
    }

    filtered_list = [...new Set(filtered_list)];

    return filtered_list;
  }
}
