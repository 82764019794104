import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteService } from '../../services/site.service';
import { SubSiteService } from '../../services/sub-site.service';
import { Site } from '../../class/site.class';
import { SubSite } from '../../class/sub-site.class';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'site-select',
  templateUrl: './site-select.component.html',
  styleUrls: ['./site-select.component.scss'],
})
export class SiteSelectComponent implements OnInit {


  public sites_select : Array<Site> = []

  public sub_sites_select : Array<SubSite> = []

  @Input() 
  public formGroup: FormGroup;

  @Output() 
  public onRemoveEvent = new EventEmitter<FormGroup>();

  @Output() 
  public onSiteSelectEvent = new EventEmitter<string>();

  get f() { return this.formGroup.controls; }

  @Input() 
  public edit : boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private siteService: SiteService,
    private subSiteService: SubSiteService) { 

      


    }



  async ngOnInit() {

    await this.siteService.getSelect().toPromise().then(data => {
      this.sites_select = data;
    })

  }

  onSiteSelect($event : any){

    
    let selectedId = $event.detail.value;
    this.formGroup.controls['site_id'].setValue(selectedId);
    

    this.subSiteService.getSelect(selectedId).toPromise().then(data => {
      this.sub_sites_select = data;
    })

    this.onSiteSelectEvent.emit(selectedId)

  }

  onSubSiteSelect($event : any){

    let selectedId = $event.detail.value;
    this.formGroup.controls['sub_site_id'].setValue(selectedId);

    // this.subSiteService.getSelect(site.id).toPromise().then(data => {
    //   this.sub_sites_select = data;
    // })

  }

  remove(){    
    this.onRemoveEvent.emit()
  }

}
