import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { System } from '../../class/system.class';
import { SystemService } from '../../services/system.service';
import { MainteneancePredictiveService } from '../../services/mainteneance-predictive.service';
import { MainteneancePreventiveService } from '../../services/mainteneance-preventive.service';

@Component({
  selector: 'item-mainteneance',
  templateUrl: './item-mainteneance.component.html',
  styleUrls: ['./item-mainteneance.component.scss'],
})
export class ItemMainteneanceComponent implements OnInit {

  public system_select : Array<System> = []

  // public mainteneance_select : Array<System> = []

  // public m_predictive_select : Array<any> = [];
  public m_preventive_select : Array<any> = [];

  @Input()
  public formGroupItemMainteneance: FormGroup;

  @Input()
  public edit: boolean = true;

  @Output() 
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroupItemMainteneance.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private systemService : SystemService,
    // private  mPredictiveService: MainteneancePredictiveService,
    private  mPreventiveService: MainteneancePreventiveService,) {

      // this.formGroupItemMainteneance.get['system_id'].valueChanges.subscribe(data=>{

      //   this.getMaintenenanceList(this.formGroupItemMainteneance.controls['system_id'].value);

      // })
      
    }

  async ngOnInit() {

    await this.systemService.getSelect().toPromise().then(data => {
      this.system_select = data;

      if(this.formGroupItemMainteneance.controls['system_id'].value){
      
        this.formGroupItemMainteneance.controls['system_id'].setValue(this.formGroupItemMainteneance.controls['system_id'].value);
        this.getMaintenenanceList(this.formGroupItemMainteneance.controls['system_id'].value);
       
      }

    })


    this.formGroupItemMainteneance.valueChanges.subscribe(data =>{
      console.log(this.formGroupItemMainteneance);
      
    })

    

  }


  onMainteneanceSelect($event : any){
    let selectedId = $event.detail.value;
    this.formGroupItemMainteneance.controls['mainteneance_preventive_id'].setValue(selectedId);
  }

  onSystemSelect($event : any){
    let selectedId = $event.detail.value;
    this.formGroupItemMainteneance.controls['system_id'].setValue(selectedId);

    this.getMaintenenanceList(selectedId);
  }


  async getMaintenenanceList(selectedId : string){

    let systemSelected = await this.systemService.getById(selectedId).toPromise()

    // await this.mPredictiveService.getSelect(systemSelected.system_type_id).toPromise().then(data => {
    //   this.m_predictive_select = data;
    // });

    await this.mPreventiveService.getSelect(systemSelected.system_type_id).toPromise().then(data => {
      this.m_preventive_select = data;
    });

    // this.mainteneance_select = this.m_preventive_select.concat(this.m_predictive_select)
    
  }

  remove(){    
    this.onRemove.emit()
  }


}
