import { Company } from './company.class'
import { Base } from "./base.class"
import { Role } from './role.class';

export enum UserRoleTypeEnum {
    ADMIN = "admin",
    USER = "user"
}


export class User extends Base{
    id: string;

    internal_code:string;

    name: string;
    surname: string;   
    email : string;
    phone: string;

    password: string;

    active : boolean;

    image_base_64 : string | null;

    company : Company | null;
    company_id: string    

    role: UserRoleTypeEnum | string;

    role_permission: Role;
    role_permission_id: string;
}