import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { System } from '../../class/system.class';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'fuel',
  templateUrl: './fuel.component.html',
  styleUrls: ['./fuel.component.scss'],
})
export class FuelComponent implements OnInit {

  public system_select : Array<System> = []

  @Input()
  public formGroupFuel: FormGroup;

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroupFuel.controls; }

  @Input()
  public edit: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private systemService : SystemService) {

      

     }

  async ngOnInit() {

    await this.systemService.getSelect().toPromise().then(data => {
      this.system_select = data;

      if(this.formGroupFuel.controls['system_id'].value){
      
        // for (const iterator of this.siteReport.ddt_transport) {
        //   this.add();
        // }
        this.formGroupFuel.controls['system_id'].setValue(this.formGroupFuel.controls['system_id'].value);
        
      }
    })

  }

  public onSystemSelect($event: any) {


    let inputValue = $event.detail.value;
    let inputName = $event.target.control;
    
    this.formGroupFuel.controls['system_id'].setValue(inputValue);
  }

  public onSelectChange($event: any) {


    let inputValue = $event.detail.value;
    let inputName = $event.target.control;
    
    this.formGroupFuel.controls['type'].setValue(inputValue);
  }

  remove() {
    this.onRemove.emit()
  }

}
