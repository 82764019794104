import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take, finalize, tap } from 'rxjs/operators';
import {
  Router
} from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { EffectLoadingService } from '../services/effect-loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests: number = 0;

  constructor(private effectLoading: EffectLoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    this.effectLoading.Show();
    

    this.totalRequests++;

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {

      //console.log(event);

      
      
      
      if (event instanceof HttpResponse) {

        // this.totalRequests--;

        // if (this.totalRequests === 0){
        //   // console.log("hide");
        //   this.effectLoading.Hide();
        // }

        setTimeout(() => {
          this.effectLoading.Hide();        
        }, 1000);

      }

      
    }, (error) => {

      if (this.totalRequests === 0){       
        this.effectLoading.Hide();
      }

    }));


  }


}