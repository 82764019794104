import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TokenService } from './token.service';
import { RolePermission } from '../class/role-permission.class';
import { NiceRolePermission } from '../class/nice-role-permission.dto';
import { UserRoleTypeEnum } from '../class/user.class';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly CURRENT_USER = "USER";

  private readonly ROLE_PERMISSION = "ROLE_PERMISSION";

  private currentUserSubject: BehaviorSubject<any | null>;
  public currentUser: Observable<any | null>;

  private currentUserRolePermissionSubject: BehaviorSubject<any | null>;
  public currentUserRolePermission: Observable<any | null>;

  //private currentUserRolePermissionSubject: BehaviorSubject<Array<RolePermission> | null>;

  constructor(
    private router: Router,
    private http: HttpClient,    
    private tokenService : TokenService) {

    this.currentUserSubject = new BehaviorSubject<any | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserRolePermissionSubject = new BehaviorSubject<any | null>(null);
    this.currentUserRolePermission = this.currentUserRolePermissionSubject.asObservable();

    //this.currentUserRolePermissionSubject = 

  }

  public get currentUserValue(): any | null {

    //console.log("AuthService get currentUserValue");        

    if(this.currentUserSubject.value === null){
      let storedUser = localStorage.getItem(this.CURRENT_USER);
       
      if(storedUser){
        this.currentUserSubject.next(JSON.parse(storedUser));
      } else {
        this.currentUserSubject.next(null)
      }
      
    }

    return this.currentUserSubject.value;

  }

  public set currentUserValue(user: any | null) {

    //console.log("AuthService set currentUserValue");

    this.currentUserSubject.next(user);

    if(user){

      localStorage.setItem(this.CURRENT_USER, JSON.stringify(user));

    } else {

      localStorage.removeItem(this.CURRENT_USER);

    }
    

  }


  public get currentUserRolePermissionValue(): Array<NiceRolePermission> | null {

    if(this.currentUserRolePermissionSubject.value === null){
      let storedPermission = localStorage.getItem(this.ROLE_PERMISSION);
      

      if(storedPermission){
        this.currentUserRolePermissionSubject.next(JSON.parse(storedPermission));
      } else {
        this.currentUserRolePermissionSubject.next(null);
      }
    }


    // let storedPermission = localStorage.getItem(this.ROLE_PERMISSION);

    // // console.log("storedPermission", storedPermission);

    // if(!storedPermission) return []

    // return JSON.parse(storedPermission)

    return this.currentUserRolePermissionSubject.value

  }

  public set currentUserRolePermissionValue(rolePermissions: Array<NiceRolePermission> | null) {

    // console.log("currentUserRolePermissionValue", rolePermissions);

    // localStorage.setItem(this.ROLE_PERMISSION, JSON.stringify(rolePermissions));
    
    this.currentUserRolePermissionSubject.next(rolePermissions);

    if(rolePermissions){

      localStorage.setItem(this.ROLE_PERMISSION, JSON.stringify(rolePermissions));

    } else {

      localStorage.removeItem(this.ROLE_PERMISSION);

    }

  }
 

  login(email: string, password: string) {

    this.currentUserValue = null
    this.currentUserRolePermissionValue = []
    
    return this.http.post<any>(`${environment.api_site}/auth/sign-in`, { email, password })
    .pipe(
      switchMap(async (tokens: {user, accessToken, refreshToken }) => {

        // console.log(tokens);
        
   
        this.tokenService.saveToken(tokens.accessToken);
        this.tokenService.saveRefreshToken(tokens.refreshToken);
        
        //this.currentUserSubject.next({});

        this.currentUserValue = tokens.user;

        // this.tokenService.startRefreshTokenTimer();

        await this.updateUserRolePermission()

        

        return from(Promise.all([]));

      })

    );

  }


  checkPassword(id: string, password: string) {
    
    return this.http.post<any>(`${environment.api_site}/auth/check-pwd`, { id, password })

  }


  requestRecovery(email: string) {
    
    return this.http.post<any>(`${environment.api_site}/auth/request-recovery`, { email });

  }

  setNewPassword(recoveryToken: string, password: string) {
    
    return this.http.post<any>(`${environment.api_site}/auth/recovery/${recoveryToken}`, { password });

  }


  logout() {

    // console.log("logout");
    

    let token = this.tokenService.getToken();

    if(token){
      this.http.delete<any>(`${environment.api_site}/auth/logout/${this.tokenService.getToken()}`, {}).subscribe();
    }


    // this.tokenService.stopRefreshTokenTimer();

    this.currentUserValue = null;

    localStorage.clear();

    this.router.navigate(['/login']);

  }

  
  getUserRolePermission(userId: string) {
    
    return this.http.get<Array<NiceRolePermission>>(`${environment.api_site}/role/user/${userId}`);    

  }


  async updateUserRolePermission(){

    // console.log("currentUserValue", this.currentUserValue);
    // console.log("currentUserValue.role", this.currentUserValue.role);
    // console.log("updateUserRolePermission",this.currentUserValue, this.currentUserValue.role);
    
    

    if(this.currentUserValue && this.currentUserValue.role !== UserRoleTypeEnum.ADMIN){
      // non è admin carico i permessi          
      await this.getUserRolePermission(this.currentUserValue.id).toPromise().then(data => {
        // console.log('getUserRolePermission', data);
        
        this.currentUserRolePermissionValue = data;
      })
    } else {
      // è admin
      this.currentUserRolePermissionValue = []
    }
    
  }


  



  // checkLogin(device_id: string, password: string) {

  //   return this.http.post<any>(`${environment.api_site}/auth/check-login`, { device_id, password }).pipe(
  //     map((data) => {
  //       return data;
  //     })
  //   );

  // }

  // register(user: any) {

  //   return this.http.post<any>(`${environment.api_site}/auth/register`, user);

  // }

  accountRecoveryPasswordRequest(email: string) {
    return this.http.post<any>(`${environment.api_site}/auth/request-recovery`, { email });
  }

  accountChangePassworRequest(token: string,email: string, password: string) {
		return this.http.post<any>(`${environment.api_site}/auth/recovery`, { token, email, password });
  }

  

  isInRole(rolesAllowed: Array<any>) {       

    if(this.currentUserValue && rolesAllowed.length == 0) return true;

    let result =  this.currentUserValue && rolesAllowed.includes(this.currentUserValue.role);
    
    return result;
  }

}
