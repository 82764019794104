import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, forwardRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'activity-site-time',
  templateUrl: './activity-site-time.component.html',
  styleUrls: ['./activity-site-time.component.scss'],

})
export class ActivitySiteTimeComponent implements OnInit {

  // @Input()
  // public index: any;

  @Input()
  public formGroupActivitySiteTime: FormGroup;

  @Input()
  public time_slot: Array<any> = [];

  // @Input()
  // public site_id: string

  // public _sites:Array<any>;

  // @Input()
  // public sitesSelected: Array<any> = [];

  ngOnChanges(changes: SimpleChange) {
    // Extract changes to the input property by its name

    // console.log(changes);
    
    // if (changes['site_id'] && changes['site_id']?.previousValue?.length !== changes['site_id']?.currentValue?.length) {

    //   this.formGroup.controls['site_id'].setValue(this.site_id);

    // }


    //if (changes['sitesSelected'] && changes['sitesSelected']?.previousValue?.length !== changes['sitesSelected']?.currentValue?.length) {
    

      // let controlsInForm = Object.keys(this.formGroup.controls);

      // for (const control of controlsInForm) {
      //   if (control !== 'activity_id') {
      //     this.formGroup.removeControl(control);
      //   }
      // }

      // for (let i = 0; i < this.sitesSelected.length; i++) {
      //   // const element = array[index];      
      //   let controlName: string = `site_${i.toString()}`;

      //   this.formGroup.addControl(controlName, new FormControl({ value: '', disabled: !this.edit }))

      // }

    //}


    // Whenever the data in the parent changes, this method gets triggered
    // You can act on the changes here. You will have both the previous
    // value and the  current value here.
  }



  // @Input()
  // public activity: any = [];

  @Output()
  public onRemove = new EventEmitter<FormGroup>();

  get f() { return this.formGroupActivitySiteTime.controls; }

  @Input()
  public edit: boolean = true;

  public activityName: string = ''

  public activity_id: string = ''

  constructor(
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {

    // this.activity_id = this.formGroup.controls['activity_id'].value

    // this.activityName = this.activity.find(v => v.id === this.activity_id)?.name;


  }


  onTimeChange($event: any) {


    let inputValue = $event.detail.value;
    let inputName = $event.target.control;
    
    this.formGroupActivitySiteTime.controls['time'].setValue(inputValue);



  }

  remove() {
    this.onRemove.emit()
  }



}
